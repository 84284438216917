import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { RouteContext } from '@atlassian/jira-router';
import type AtlasGoalsType from '@atlassian/jira-spa-apps-goals/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyAlasGoalsClassic = lazyForPaint<typeof AtlasGoalsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-atlas-goals", jiraSpaEntry: "async-atlas-goals" */ '@atlassian/jira-spa-apps-goals/'
		),
	),
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ match }: RouteContext) => (
	<LazyPage
		Page={LazyAlasGoalsClassic}
		pageId={`classic-atlas-goals-${match.params.boardId || ''}`}
		shouldShowSpinner={false}
	/>
);
