import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { RouteContext } from '@atlassian/jira-router';
import type JiraBusinessListViewType from '@atlassian/jira-spa-apps-jsw-list';
import type SoftwareCodeType from '@atlassian/jira-spa-apps-software-code/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import { getBoardId } from './common';

export const LazyCode = lazyForPaint<typeof SoftwareCodeType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-code-simplified", jiraSpaEntry:  "async-code-simplified" */ '@atlassian/jira-spa-apps-software-code'
		),
	),
);

export const LazySoftwareListView = lazyForPaint<typeof JiraBusinessListViewType>(
	() =>
		import(
			/* webpackChunkName: "async-software-list", jiraSpaEntry: "async-software-list" */ '@atlassian/jira-spa-apps-jsw-list'
		),
);

export const LazySoftwareListEmbedView = lazyForPaint<typeof JiraBusinessListViewType>(
	() =>
		import(
			/* webpackChunkName: "async-software-list-embed", jiraSpaEntry: "async-software-list-embed" */ '@atlassian/jira-spa-apps-jsw-list-embed'
		),
);

export const CodePageNextGen = ({ location, match }: RouteContext) => (
	<LazyPage
		Page={LazyCode}
		pageId={`next-gen-code-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);

export const CodePageClassic = ({ location, match }: RouteContext) => (
	<LazyPage
		Page={LazyCode}
		pageId={`classic-code-${getBoardId({ location, match })}`}
		shouldShowSpinner={false}
	/>
);

export const SoftwareListPage = ({ location, match }: RouteContext) => (
	<LazyPage
		Page={LazySoftwareListView}
		pageId={`software-${getBoardId({ location, match })}-list`}
		shouldShowSpinner
	/>
);

export const SoftwareListEmbedPage = ({ location, match }: RouteContext) => (
	<LazyPage
		Page={LazySoftwareListEmbedView}
		pageId={`software-${getBoardId({ location, match })}-list-embed`}
		shouldShowSpinner
	/>
);
