import { useMemo } from 'react';
import { DEFAULT_BACKGROUND_CONFIG } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import type { ProjectTheme } from '@atlassian/jira-custom-theme-constants/src/types.tsx';
import { projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details';
import { useResource } from '@atlassian/react-resource-router';

export const useProjectTheme = (): ProjectTheme | null => {
	const { data } = useResource(projectDetailsResource);

	return useMemo(() => {
		if (data != null) {
			const entityId = `project/${data.currentProject?.id}`;

			const themeSetting = data.themeSetting ?? {
				type: 'color',
				value: DEFAULT_BACKGROUND_CONFIG.name,
			};

			return {
				themeSetting,
				entityId,
			};
		}

		return null;
	}, [data]);
};
