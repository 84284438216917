import { ff } from '@atlassian/jira-feature-flagging';
import { JIRA_PRODUCT_DISCOVERY } from '@atlassian/jira-shared-types/src/application.tsx';
import {
	getEdition,
	toEdition,
	PREMIUM_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export const useIsPremiumEdition = () => {
	const { appEditions } = useTenantContext();
	const jpdEdition = getEdition(JIRA_PRODUCT_DISCOVERY, appEditions);

	// TODO: POL-10089 use entitlements instead of the edition when it will be available
	return jpdEdition === toEdition(PREMIUM_EDITION);
};

export const useIsViewPermissionsEnabled = () => {
	const isPremium = useIsPremiumEdition();

	return isPremium && ff('polaris.view-permissions_plaoi');
};

export const useIsCrossProjectViewsEnabled = () => {
	const isPremium = useIsPremiumEdition();

	return isPremium;
};
