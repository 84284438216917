import {
	ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_DETAILS,
	ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION,
	ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS_NOTIFICATION_EMAIL,
	ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS,
	ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_FORGE_APPS,
	ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_EMAIL_SETTINGS,
} from '@atlassian/jira-common-constants/src/spa-routes';

const SERVICE_DESK_PRODUCT = 'servicedesk';
export const getJsmProjectSettingsUrl = (projectKey?: string) =>
	`/jira/${SERVICE_DESK_PRODUCT}/projects/${projectKey || ':projectKey'}/settings`;
const jsmProjectSettingsUrl = getJsmProjectSettingsUrl();

// "Request types" in TMP project use this
export const routeGroupsProjectSettingsServicedeskIssuetypesRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-issuetypes`,
	path: `${jsmProjectSettingsUrl}/issuetypes/:issueTypeId?`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskIssuetypesWorkflowRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-issuetypes-workflow`,
	path: `${jsmProjectSettingsUrl}/issuetypes/:issueTypeId/:initialSection(workflow)`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskRequestTypesWorkCategory = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-request-types-work-category`,
	path: `${jsmProjectSettingsUrl}/request-types/category/:practice`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskFieldsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-fields`,
	path: `${jsmProjectSettingsUrl}/fields`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskAppsFieldsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-apps-fields`,
	path: `${jsmProjectSettingsUrl}/apps/app-fields`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskApps1Route = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-apps`,
	path: `${jsmProjectSettingsUrl}/apps/:appId?`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskDetailsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_DETAILS,
	// /settings/details
	path: `${jsmProjectSettingsUrl}/details`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskAccessRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-access`,
	path: `${jsmProjectSettingsUrl}/access`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskAccessRedirectRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-access-redirect`,
	path: `${jsmProjectSettingsUrl}/people`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskProformaProjectFormsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-proforma-project-forms`,
	path: `${jsmProjectSettingsUrl}/forms`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskProformaJiraFormBuilderRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-proforma-jira-form-builder`,
	path: `${jsmProjectSettingsUrl}/forms/form/:templateFormId/edit`,
	exact: true,
};

export const projectSettingsServicedeskAutomationRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PROJECT_SETTINGS_AUTOMATION,
	path: `${jsmProjectSettingsUrl}/automate`,
};

export const routeGroupsProjectSettingsServicedeskDataClassificationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-data-classifications`,
	path: `${jsmProjectSettingsUrl}/data-classifications`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskClassicAppsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-classic-apps`,
	path: `${jsmProjectSettingsUrl}/apps`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskItsmFeaturesRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-itsm-features`,
	path: `${jsmProjectSettingsUrl}/itsm-features`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskNotificationsInternalNotificationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-notifications-internal-notifications`,
	path: `${jsmProjectSettingsUrl}/notifications/internal-notifications`,
};

export const routeGroupsProjectSettingsServicedeskNotificationsInternalNotificationsNextGenRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-notifications-internal-notifications-nextGen`,
	path: `${jsmProjectSettingsUrl}/internal-notifications`,
};

export const projectSettingsServicedeskNotificationsNotificationEmailRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS_NOTIFICATION_EMAIL,
	path: `${jsmProjectSettingsUrl}/notifications/notification-email`,
	exact: true,
};

export const projectSettingsServicedeskNotificationsEmailRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS_NOTIFICATION_EMAIL,
	path: `${jsmProjectSettingsUrl}/notification-email`,
	exact: true,
};

export const projectSettingsServicedeskNotifications1Route = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_NOTIFICATIONS,
	path: `${jsmProjectSettingsUrl}/notifications`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskLanguageKeyRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-language-key`,
	path: `${jsmProjectSettingsUrl}/language/:languageKey/:sidebarItemKey?`,
};

export const routeGroupsProjectSettingsServicedeskLanguage1Route = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-language`,
	path: `${jsmProjectSettingsUrl}/language`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskChannelsPermissionsNextGenRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-channels-permissions-next-gen`,
	path: `${jsmProjectSettingsUrl}/channels/customer-permissions`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskCustomerPermissionsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-customer-permissions`,
	path: `${jsmProjectSettingsUrl}/customer-permissions`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskChannelsEmailNextGenRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-channels-email-next-gen`,
	path: `${jsmProjectSettingsUrl}/channels/email`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskEmailRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_EMAIL_SETTINGS,
	path: `${jsmProjectSettingsUrl}/email`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskPortalSettingsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-portal-settings`,
	path: `${jsmProjectSettingsUrl}/portal-settings/:subPath(portal-groups)?`,
	exact: true,
};

export const routeGroupsProjectSettingsLegacyAutomationRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-automation`,
	path: `${jsmProjectSettingsUrl}/automation`,
	exact: true,
};

export const routeGroupsProjectSettingsVirtualAgentSettingsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtual-agent`,
	path: `${jsmProjectSettingsUrl}/virtual-agent`,
	exact: true,
};

export const virtualAgentIntentsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtual-agent-intents`,
	path: `${jsmProjectSettingsUrl}/virtual-agent/intents`,
	exact: true,
};

export const virtualAgentStandardFlowsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtual-agent-standard-flows`,
	path: `${jsmProjectSettingsUrl}/virtual-agent/standard-flows`,
	exact: true,
};

export const virtualAgentConversationsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtual-agent-conversations`,
	path: `${jsmProjectSettingsUrl}/virtual-agent/conversations`,
	exact: true,
};

export const virtualAgentSettingsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-virtual-agent-settings`,
	path: `${jsmProjectSettingsUrl}/virtual-agent/settings`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskChannelsPortalRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-channels-portal`,
	path: `${jsmProjectSettingsUrl}/channels/portal-settings`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskExternalLinksRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-external-links`,
	path: `${jsmProjectSettingsUrl}/external-links`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskChannelsWidgetRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-channels-widget`,
	path: `${jsmProjectSettingsUrl}/channels/widget`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskWidgetRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-widget`,
	path: `${jsmProjectSettingsUrl}/widget`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskChangeManagementRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-change-management`,
	path: `${jsmProjectSettingsUrl}/change-management`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskIncidentManagementRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-incident-management`,
	path: `${jsmProjectSettingsUrl}/incident-management`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskChannelsChatRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-channels-chat`,
	path: `${jsmProjectSettingsUrl}/channels/chat`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskChatRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-chat`,
	path: `${jsmProjectSettingsUrl}/chat`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskChannels5Route = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-channels`,
	path: `${jsmProjectSettingsUrl}/channels`,
	exact: true,
};

export const routeGroupsProjectSettingsServicedeskFeedbackRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-feedback`,
	path: `${jsmProjectSettingsUrl}/feedback-settings`,
};

export const projectSettingsServicedeskForgeAppsRoute = {
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: ROUTE_NAMES_PROJECT_SETTINGS_SERVICEDESK_FORGE_APPS,
	path: `${jsmProjectSettingsUrl}/apps/:appId/:envId/:route?`,
};

export const routeGroupsProjectSettingsServicedeskRedirectRoute = {
	// the following route should be non-exact, it will handle "index" and "404" cases
	group: ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK,
	name: `${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-redirect`,
	path: jsmProjectSettingsUrl,
};
