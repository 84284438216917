import { useNavigationResource } from '@atlassian/jira-router-resources-business-navigation';
import { NOT_APPLICABLE } from '@atlassian/jira-router-resources-business-navigation/src/constants.tsx';
import type { NavigationProject } from '@atlassian/jira-router-resources-business-navigation/src/types.tsx';
import { useProject } from '@atlassian/jira-router-resources-business-project-details';
import { useRouter } from '@atlassian/react-resource-router';

type ReturnValue = {
	project: NavigationProject | null;
	loading: boolean;
};

export const useNavigationProject = (): ReturnValue => {
	const { data: currentProject, loading: currentProjectLoading } = useProject();
	const { data: navigationData, loading: navigationDataLoading } = useNavigationResource();

	const [
		{
			match: {
				params: { projectKey },
			},
		},
	] = useRouter();

	if (currentProject != null) {
		return { project: currentProject, loading: currentProjectLoading };
	}

	if (projectKey != null && navigationData != null && navigationData !== NOT_APPLICABLE) {
		const project =
			navigationData.favorites.find((proj) => proj.key === projectKey) ??
			navigationData.recents.find((proj) => proj.key === projectKey) ??
			null;
		return { project, loading: navigationDataLoading };
	}

	return { project: null, loading: currentProjectLoading || navigationDataLoading };
};
