import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmChangeManagementSettingsGitLabGatingResource } from '@atlassian/jira-router-resources-jsm-change-management-settings/src/controllers/index.tsx';
import { jsmRepackagingBannerResource } from '@atlassian/jira-router-resources-jsm-repackaging-banner';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { fieldSettingsRelayResource } from '@atlassian/jira-router-resources-project-settings-fields';
import { serviceDeskContextResource } from '@atlassian/jira-router-resources-service-desk-context/src/services/index.tsx';
import { customerPermissionsPageDataResource } from '@atlassian/jira-router-resources-service-desk-customer-permissions/src/services/customer-permissions-page/index.tsx';
import { portalSettingsContextResource } from '@atlassian/jira-router-resources-service-desk-portal-settings-context/src/services';
import { featuresResource } from '@atlassian/jira-router-resources-service-project-features/src/services/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { playbooksRouteEntries } from '@atlassian/jira-router-routes-project-settings-playbooks-entries';
import {
	routeGroupsProjectSettingsServicedeskIssuetypesRoute,
	routeGroupsProjectSettingsServicedeskIssuetypesWorkflowRoute,
	routeGroupsProjectSettingsServicedeskAppsFieldsRoute,
	routeGroupsProjectSettingsServicedeskApps1Route,
	routeGroupsProjectSettingsServicedeskDetailsRoute,
	routeGroupsProjectSettingsServicedeskAccessRoute,
	routeGroupsProjectSettingsServicedeskAccessRedirectRoute,
	routeGroupsProjectSettingsServicedeskProformaProjectFormsRoute,
	routeGroupsProjectSettingsServicedeskProformaJiraFormBuilderRoute,
	projectSettingsServicedeskAutomationRoute,
	routeGroupsProjectSettingsServicedeskDataClassificationsRoute,
	routeGroupsProjectSettingsServicedeskClassicAppsRoute,
	routeGroupsProjectSettingsServicedeskItsmFeaturesRoute,
	routeGroupsProjectSettingsServicedeskNotificationsInternalNotificationsRoute,
	projectSettingsServicedeskNotificationsNotificationEmailRoute,
	projectSettingsServicedeskNotifications1Route,
	routeGroupsProjectSettingsServicedeskLanguageKeyRoute,
	routeGroupsProjectSettingsServicedeskLanguage1Route,
	routeGroupsProjectSettingsServicedeskChannelsPermissionsNextGenRoute,
	routeGroupsProjectSettingsServicedeskCustomerPermissionsRoute,
	routeGroupsProjectSettingsServicedeskChannelsEmailNextGenRoute,
	routeGroupsProjectSettingsServicedeskEmailRoute,
	routeGroupsProjectSettingsServicedeskPortalSettingsRoute,
	routeGroupsProjectSettingsServicedeskChannelsPortalRoute,
	routeGroupsProjectSettingsServicedeskExternalLinksRoute,
	routeGroupsProjectSettingsServicedeskChannelsWidgetRoute,
	routeGroupsProjectSettingsServicedeskWidgetRoute,
	routeGroupsProjectSettingsServicedeskChangeManagementRoute,
	routeGroupsProjectSettingsServicedeskIncidentManagementRoute,
	routeGroupsProjectSettingsServicedeskChannelsChatRoute,
	routeGroupsProjectSettingsServicedeskChatRoute,
	routeGroupsProjectSettingsServicedeskChannels5Route,
	routeGroupsProjectSettingsServicedeskFeedbackRoute,
	projectSettingsServicedeskForgeAppsRoute,
	routeGroupsProjectSettingsServicedeskRedirectRoute,
	routeGroupsProjectSettingsServicedeskFieldsRoute,
	routeGroupsProjectSettingsServicedeskNotificationsInternalNotificationsNextGenRoute,
	projectSettingsServicedeskNotificationsEmailRoute,
} from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes';
import { legacyAutomationRouteEntries } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-entries';
import { customerNotificationsSettingsRouteEntries } from '@atlassian/jira-router-routes-project-settings-service-desk-customer-notifications-entries';
import { journeysRouteEntries } from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-entries';
import { knowledgeSettingsRouteEntries } from '@atlassian/jira-router-routes-project-settings-service-desk-knowledge-base-entries';
import { slaSettingsRouteEntries } from '@atlassian/jira-router-routes-project-settings-service-desk-sla-settings-entries';
import { ticketTypeFieldsRouteEntries } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-type-fields-entries';
import { ticketTypesConfigRouteEntries } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-entries';
import { virtualAgentRouteEntries } from '@atlassian/jira-router-routes-project-settings-service-desk-virtual-agent-entries';
import { servicedeskWorkflowConfigRouteEntry } from '@atlassian/jira-router-routes-project-settings-workflow-scheme-config-entries';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { changeManagementAssetConfigurationStatusResource } from '@atlassian/jira-servicedesk-change-management-settings/src/services/change-management-configuration/get-assets-configuration-status/resources';
import { changeManagementSettingsProjectConfigurationResource } from '@atlassian/jira-servicedesk-change-management-settings/src/services/change-management-configuration/get-project-configuration/resources';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import { LazyCsatSettingsPage } from '@atlassian/jira-servicedesk-csat/src/async';
import {
	languageTranslationsResource,
	outgoingEmailStateModelResource,
} from '@atlassian/jira-servicedesk-language-settings-translations-resource/src';
import { LazyInternalNotificationSettingsPage } from '@atlassian/jira-servicedesk-settings-notification-internal/src/ui/async';
import {
	ProjectSettingsAccessSkeleton,
	ProjectSettingsDetailsSkeleton,
} from '@atlassian/jira-skeletons/src/ui/project-settings/index.tsx';
import ConnectAppControlsPage, {
	LazyConnectAppControlsSettings,
	ConnectAppsRedirect,
} from './ui/connect-app-controls';
import { LazyAppDiscoverySettings } from './ui/connect-app-controls/app-discovery-settings';
import ConnectAppsPage, { LazyConnectAppsSettings } from './ui/connect-apps';
import DataClassifications, { LazyDataClassificationsSettings } from './ui/data-classifications';
import Details, { LazyDetailsSettings } from './ui/details';
import FieldSettingsPage, { LazyFieldsSettings } from './ui/fields';
import ForgeProjectSettingsPage, {
	forgeProjectSettingsPageResources,
	LazyForgeProjectSettingsPage,
} from './ui/forge';
import IssueTypePage, { LazyIssueTypeSettings } from './ui/issue-type';
import Automation, { LazyProjectSettingsJiraAutomation } from './ui/jira-automation';
import EmailNotifications, { LazyProjectSettingsNotificationEmail } from './ui/notification-email';
import Access, { LazyPeopleSettings, peopleAccessRedirect } from './ui/people';
import {
	getSettingsNavigationResources,
	defaultRedirectForSettings,
	SERVICE_DESK_PRODUCT,
} from './ui/product-routes/common';
import ProformaJiraFormBuilder, {
	LazyProjectSettingsProformaJiraFormBuilder,
} from './ui/proforma-jira-form-builder';
import ProformaProjectForms, {
	LazyProjectSettingsProformaProjectForms,
} from './ui/proforma-project-forms';
import ServiceDeskChangeManagementSettings, {
	LazyProjectSettingsServiceDeskChangeManagement,
} from './ui/service-desk-change-management';
import { channelsRedirect } from './ui/service-desk-channel-redirect';
import { serviceDeskChatNextGenRedirect } from './ui/service-desk-chat-next-gen-redirect';
import ServiceDeskChatSettings, {
	LazyProjectSettingsServiceDeskChatSettings,
} from './ui/service-desk-chat-settings';
import ServiceDeskClassicPortalSettings, {
	LazyProjectSettingsServiceDeskClassicPortalSettings,
} from './ui/service-desk-classic-portal-settings';
import ServiceDeskCustomerPermissions, {
	LazyProjectSettingsServiceDeskCustomerPermissions,
} from './ui/service-desk-customer-permissions';
import { customerPermissionsRedirect } from './ui/service-desk-customer-permissions-redirect';
import ServiceDeskEmail, { LazyProjectSettingsServiceDeskEmail } from './ui/service-desk-email';
import { serviceDeskEmailNextGenRedirect } from './ui/service-desk-email-next-gen-redirect';
import ExternalLinks, {
	LazyProjectSettingsServiceDeskExternalLinks,
} from './ui/service-desk-external-links';
import ServiceDeskFeedbackSettings, {
	LazyProjectSettingsServiceDeskFeedbackSettings,
} from './ui/service-desk-feedback-settings';
import ServiceDeskIncidentManagementSettings, {
	LazyProjectSettingsServiceDeskIncidentManagement,
} from './ui/service-desk-incident-management';
import ServiceDeskInternalNotifications, {
	LazyProjectSettingsServiceDeskInternalNotifications,
} from './ui/service-desk-internal-notifications';
import { serviceDeskInternalNotificationsNextGenRedirect } from './ui/service-desk-internal-notifications-next-gen-redirect';
import ServiceDeskItsmFeatures, {
	LazyProjectSettingsServiceDeskItsmFeatures,
} from './ui/service-desk-itsm-features';
import ServiceDeskLanguageSupport from './ui/service-desk-language-support';
import ServiceDeskLanguageTranslations, {
	LazyProjectSettingsServiceDeskLanguageTranslations,
} from './ui/service-desk-language-translations';
import { ServiceDeskNotificationEmailNextGenRedirect } from './ui/service-desk-notification-email-next-gen-redirect';
import { notificationsRedirect } from './ui/service-desk-notifications-redirect';
import ServiceDeskPortalSettings, {
	LazyProjectSettingsServiceDeskPortalSettings,
} from './ui/service-desk-portal-settings';
import { widgetsNextGenRedirect } from './ui/service-desk-widget-next-gen-redirect';
import ServiceDeskWidgetSettings, {
	LazyProjectSettingsServiceDeskWidgetSettings,
} from './ui/service-desk-widget-settings';
import WorkflowNavigationPage from './ui/workflow-navigation';

const ufoNamesMap: Record<string, string> = {
	'project-settings-software-issuetypes': 'project-settings.issue-type.jsw-tmp',
	'project-settings-servicedesk-issuetypes': 'project-settings.issue-type.jsm-tmp',
};

const getUFOName = (name: string) => ufoNamesMap[name] || name;
const commonRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskIssuetypesRoute, {
		ufoName: getUFOName(`${ROUTE_GROUPS_PROJECT_SETTINGS_SERVICEDESK}-issuetypes`),
		component: IssueTypePage,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyIssueTypeSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskIssuetypesWorkflowRoute, {
		component: WorkflowNavigationPage,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyIssueTypeSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskFieldsRoute, {
		ufoName: 'project-settings.fields.jsm-tmp',
		component: componentWithFG(
			'project_fields_page_for_tmp_-_mvp',
			FieldSettingsPage,
			ErrorPagesNotFound,
		),
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
			fieldSettingsRelayResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyFieldsSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskAppsFieldsRoute, {
		component: ConnectAppControlsPage,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyConnectAppControlsSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskApps1Route, {
		component: ConnectAppsPage,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyConnectAppsSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskDetailsRoute, {
		component: Details,
		skeleton: ProjectSettingsDetailsSkeleton,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyDetailsSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskAccessRoute, {
		skeleton: ProjectSettingsAccessSkeleton,
		component: Access,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyPeopleSettings],
		ufoName: 'project-settings.access',
	}),
	createEntry(routeGroupsProjectSettingsServicedeskAccessRedirectRoute, {
		component: peopleAccessRedirect(`/jira/${SERVICE_DESK_PRODUCT}`),
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyPeopleSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskProformaProjectFormsRoute, {
		component: ProformaProjectForms,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaProjectForms],
		perfMetricKey: 'forms-settings',
	}),
	createEntry(routeGroupsProjectSettingsServicedeskProformaJiraFormBuilderRoute, {
		component: ProformaJiraFormBuilder,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaJiraFormBuilder],
		perfMetricKey: 'form-builder',
	}),
	createEntry(projectSettingsServicedeskAutomationRoute, {
		ufoName: `project-settings-automation-${SERVICE_DESK_PRODUCT}`,
		component: Automation,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsJiraAutomation],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskDataClassificationsRoute, {
		component: DataClassifications,
		skeleton: ProjectSettingsDetailsSkeleton,
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyDataClassificationsSettings],
	}),
];

/**
 * Contains all service desk settings routes using the new url format `jira/:product/projects/:projectKey/settings`.
 */
export const serviceDeskRouteEntries: Route[] = [
	createEntry(routeGroupsProjectSettingsServicedeskClassicAppsRoute, {
		ufoName: 'service-management.app-discovery',
		component: ConnectAppsRedirect(`/jira/${SERVICE_DESK_PRODUCT}`),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		isRedirect: true,
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyAppDiscoverySettings],
		meta: {
			reporting: {
				id: APP_NAMES.APP_DISCOVERY,
				packageName: toPackageName(APP_NAMES.APP_DISCOVERY),
				teamName: 'it-solutions-hermes',
			},
		},
	}),
	...commonRouteEntries,
	...knowledgeSettingsRouteEntries,
	...ticketTypeFieldsRouteEntries,
	...ticketTypesConfigRouteEntries,
	...legacyAutomationRouteEntries,
	...customerNotificationsSettingsRouteEntries,
	...virtualAgentRouteEntries,
	...playbooksRouteEntries,
	...journeysRouteEntries,
	servicedeskWorkflowConfigRouteEntry,
	createEntry(routeGroupsProjectSettingsServicedeskItsmFeaturesRoute, {
		ufoName: 'jsm-features-settings',
		component: ServiceDeskItsmFeatures,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			featuresResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskItsmFeatures],
		// Error boudnary is added to the component itself, so this layer won't capture the error
		// Remove meta when clean up add-error-boundary-to-issue-type-package-components_2ds9q
		meta: {
			reporting: {
				id: APP_NAMES.ITSM_FEATURES_SETTINGS,
				packageName: toPackageName(APP_NAMES.ITSM_FEATURES_SETTINGS),
				teamName: 'jsd-shield',
			},
		},
		perfMetricKey: 'jsm-features-settings',
	}),
	createEntry(routeGroupsProjectSettingsServicedeskNotificationsInternalNotificationsRoute, {
		ufoName: 'service-management.internal-notifications',
		component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			serviceDeskInternalNotificationsNextGenRedirect,
			ServiceDeskInternalNotifications,
		),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [
			LazyProjectSettingsServiceDeskInternalNotifications,
			LazyInternalNotificationSettingsPage,
		],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskNotificationsInternalNotificationsNextGenRoute, {
		ufoName: 'service-management.internal-notifications',
		component: ServiceDeskInternalNotifications,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [
			LazyProjectSettingsServiceDeskInternalNotifications,
			LazyInternalNotificationSettingsPage,
		],
	}),
	createEntry(projectSettingsServicedeskNotificationsNotificationEmailRoute, {
		component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			ServiceDeskNotificationEmailNextGenRedirect,
			EmailNotifications,
		),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyProjectSettingsNotificationEmail],
	}),
	createEntry(projectSettingsServicedeskNotificationsEmailRoute, {
		component: EmailNotifications,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyProjectSettingsNotificationEmail],
	}),
	createEntry(projectSettingsServicedeskNotifications1Route, {
		component: notificationsRedirect('customer-notifications'),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskLanguageKeyRoute, {
		ufoName: 'service-management.translations',
		component: ServiceDeskLanguageTranslations,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
			languageTranslationsResource,
			outgoingEmailStateModelResource,
		],
		meta: {
			reporting: {
				id: APP_NAMES.LANGUAGE_SETTINGS,
				packageName: toPackageName(APP_NAMES.LANGUAGE_SETTINGS),
				teamName: 'jsm-nimbus',
			},
		},
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskLanguageTranslations],
		perfMetricKey: 'jsm-language-translations',
	}),
	createEntry(routeGroupsProjectSettingsServicedeskLanguage1Route, {
		ufoName: 'service-management.languages',
		component: ServiceDeskLanguageSupport,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskLanguageTranslations],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskChannelsPermissionsNextGenRoute, {
		component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			customerPermissionsRedirect,
			ServiceDeskCustomerPermissions,
		),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			projectContextResource,
			customerPermissionsPageDataResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskCustomerPermissions],
		meta: {
			reporting: {
				id: APP_NAMES.CUSTOMER_PERMISSIONS,
				packageName: toPackageName(APP_NAMES.CUSTOMER_PERMISSIONS),
				teamName: 'jsd-flux-capacitors',
			},
		},
		perfMetricKey: 'jsm-customer-permissions',
	}),

	createEntry(routeGroupsProjectSettingsServicedeskCustomerPermissionsRoute, {
		component: ServiceDeskCustomerPermissions,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			projectContextResource,
			customerPermissionsPageDataResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskCustomerPermissions],
		meta: {
			reporting: {
				id: APP_NAMES.CUSTOMER_PERMISSIONS,
				packageName: toPackageName(APP_NAMES.CUSTOMER_PERMISSIONS),
				teamName: 'jsd-flux-capacitors',
			},
		},
		perfMetricKey: 'jsm-customer-permissions',
	}),
	createEntry(routeGroupsProjectSettingsServicedeskChannelsEmailNextGenRoute, {
		component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			serviceDeskEmailNextGenRedirect,
			ServiceDeskEmail,
		),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskEmail],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskEmailRoute, {
		ufoName: 'service-management.settings.email-channels',
		component: ServiceDeskEmail,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskEmail],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskPortalSettingsRoute, {
		component: ServiceDeskClassicPortalSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskClassicPortalSettings],
		meta: {
			reporting: {
				id: APP_NAMES.PORTAL_SETTINGS,
				packageName: toPackageName(APP_NAMES.PORTAL_SETTINGS),
				teamName: 'jsd-shield',
			},
		},
	}),
	createEntry(routeGroupsProjectSettingsServicedeskChannelsPortalRoute, {
		component: ServiceDeskPortalSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			projectContextResource,
			portalSettingsContextResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskPortalSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskExternalLinksRoute, {
		component: ExternalLinks,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			projectContextResource,
			featuresResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskExternalLinks],
		perfMetricKey: 'jsm-external-resources',
	}),
	createEntry(routeGroupsProjectSettingsServicedeskChannelsWidgetRoute, {
		component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			widgetsNextGenRedirect,
			ServiceDeskWidgetSettings,
		),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskWidgetSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskWidgetRoute, {
		component: ServiceDeskWidgetSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskWidgetSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskChangeManagementRoute, {
		ufoName: 'service-management.change-management-settings',
		component: ServiceDeskChangeManagementSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			serviceDeskContextResource,
			changeManagementSettingsProjectConfigurationResource,
			jsmChangeManagementSettingsGitLabGatingResource,
			changeManagementAssetConfigurationStatusResource,
			featuresResource,
			getConsolidationStateResource(),
			resourceWithCondition2(
				() => ff('enable_jsm_repackaging_changes_vtwk9'),
				jsmRepackagingBannerResource,
			),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskChangeManagement],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskIncidentManagementRoute, {
		component: ServiceDeskIncidentManagementSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			serviceDeskContextResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskIncidentManagement],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskChannelsChatRoute, {
		component: componentWithFG(
			'update_jsm_project_settings_pages_urls',
			serviceDeskChatNextGenRedirect,
			ServiceDeskChatSettings,
		),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskChatSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskChatRoute, {
		component: ServiceDeskChatSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskChatSettings],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskChannels5Route, {
		component: channelsRedirect('customer-permissions'),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation],
	}),
	...slaSettingsRouteEntries,

	createEntry(routeGroupsProjectSettingsServicedeskFeedbackRoute, {
		component: ServiceDeskFeedbackSettings,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyProjectSettingsServiceDeskFeedbackSettings, LazyCsatSettingsPage],
		perfMetricKey: 'jsm-feedback-settings',
		meta: {
			reporting: {
				id: APP_NAMES.CSAT_CONFIG,
				packageName: toPackageName(APP_NAMES.CSAT_CONFIG),
				teamName: 'jsm-request-intake',
			},
		},
	}),
	createEntry(projectSettingsServicedeskForgeAppsRoute, {
		component: ForgeProjectSettingsPage,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			...forgeProjectSettingsPageResources,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyForgeProjectSettingsPage],
	}),
	createEntry(routeGroupsProjectSettingsServicedeskRedirectRoute, {
		component: defaultRedirectForSettings(SERVICE_DESK_PRODUCT),
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getSettingsNavigationResources(SERVICE_DESK_PRODUCT),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation],
	}),
];
