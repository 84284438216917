import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { AsyncHorizontalProjectNav } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout';
import { ThemedLazySkeleton } from '@atlassian/jira-business-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common';
import { activityFeedResource } from '@atlassian/jira-router-resources-business-summary-activity-feed';
import { jswOnboardingResource } from '@atlassian/jira-router-resources-jsw-onboarding';
import {
	getNavigationResources,
	getNavigationSidebarProjectResource,
} from '@atlassian/jira-router-resources-navigation';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import {
	softwareOnboardingRoute,
	softwareOnboardingGetStartedRoute,
	businessOnboardingGetStartedRoute,
} from '@atlassian/jira-router-routes-software-onboarding-routes';
import { AsyncHorizontalSoftwareProjectNav } from '@atlassian/jira-software-horizontal-nav/src/async.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout';
import type OnboardingGetStartedPageType from '@atlassian/jira-spa-apps-onboarding-get-started';
import type SoftwareOnboardingPageType from '@atlassian/jira-spa-apps-software-onboarding';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazySoftwareOnboardingPage = lazyForPaint<typeof SoftwareOnboardingPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-software-onboarding", jiraSpaEntry: "async-software-onboarding" */ '@atlassian/jira-spa-apps-software-onboarding'
		),
	),
);

export const LazyOnboardingGetStartedPage = lazyForPaint<typeof OnboardingGetStartedPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-onboarding-get-started", jiraSpaEntry: "async-onboarding-get-started" */ '@atlassian/jira-spa-apps-onboarding-get-started'
		),
	),
);

export const SoftwareOnboardingPage = () => (
	<LazyPage
		Page={LazySoftwareOnboardingPage}
		pageId="software-onboarding"
		shouldShowSpinner={false}
	/>
);

export const OnboardingGetStartedPage = () => (
	<LazyPage
		Page={LazyOnboardingGetStartedPage}
		pageId="onboarding-get-started"
		shouldShowSpinner={false}
	/>
);

export const softwareOnboardingRouteEntries: Route[] = [
	createEntry(softwareOnboardingRoute, {
		component: SoftwareOnboardingPage,
		layout: chromelessLayout,
		resources: [jswOnboardingResource, themePreferenceResource],
		forPaint: [LazySoftwareOnboardingPage],
		canTransitionIn: () => false,
	}),
];

export const onboardingGetStartedRouteEntries: Route[] = [
	createEntry(softwareOnboardingGetStartedRoute, {
		component: componentWithCondition(
			() => fg('jira_one_screen_onboarding_feature_gate'),
			OnboardingGetStartedPage,
			ErrorPagesNotFound,
		),
		layout: softwareProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			horizontal: AsyncHorizontalSoftwareProjectNav,
			onlyShowHorziontalOnNav4: true,
		},
		resources: [...getNavigationResources(), ...getNavigationSidebarProjectResource()],
		forPaint: [LazyAtlassianNavigation, LazyOnboardingGetStartedPage],
		canTransitionIn: () => false,
		ufoName: 'software-onboarding-get-started',
	}),
	createEntry(businessOnboardingGetStartedRoute, {
		component: componentWithCondition(
			() => fg('jira_one_screen_onboarding_feature_gate'),
			OnboardingGetStartedPage,
			ErrorPagesNotFound,
		),
		layout: businessProjectLayout,
		skeleton: ThemedLazySkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalProjectNav,
		},
		resources: [...getProjectViewResources(), activityFeedResource],
		forPaint: [LazyAtlassianNavigation, LazyOnboardingGetStartedPage],
		ufoName: 'business-onboarding-get-started',
	}),
];
