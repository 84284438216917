import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { forgeProjectSettingsModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { projectContextResourceWithCache } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import type ForgeProjectSettingsPageType from '@atlassian/jira-spa-apps-forge-project-settings';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyForgeProjectSettingsPage = lazyForPaint<typeof ForgeProjectSettingsPageType>(
	() =>
		import(
			/* webpackChunkName: "async-forge-spa-project-settings", jiraSpaEntry: "async-forge-spa-project-settings" */ '@atlassian/jira-spa-apps-forge-project-settings'
		),
);

export const forgeProjectSettingsPageResources = [
	projectContextResourceWithCache,
	forgeProjectSettingsModuleResource,
] as const;

export const ForgeProjectSettingsPage = () => (
	<LazyPage
		Page={LazyForgeProjectSettingsPage}
		pageId="forge-spa-project-settings"
		shouldShowSpinner
	/>
);

export default ForgeProjectSettingsPage;
