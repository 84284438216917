import React from 'react';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';
import { useRouter, useResource } from '@atlassian/jira-router';
import {
	advancedRoadmapsScenarioIdResource,
	advancedRoadmapsPlanPageInitialChecksResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import { ErrorScreen } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/error-screens/index.tsx';

const PERSIST_SEARCH_PARAMS = ['search'];

const RedirectLegacyPlanPageWithoutScenarioId = () => {
	const [{ query }] = useRouter();
	const { data, loading } = useResource(advancedRoadmapsScenarioIdResource);

	if (loading || data === null) {
		return null;
	}

	return (
		<ScreenRedirect
			to={`/jira/plans/${query.id}/scenarios/${data.scenarioId}`}
			persistSearchParams={PERSIST_SEARCH_PARAMS}
		/>
	);
};

export const RedirectLegacyPlanPage = () => {
	const [{ query }] = useRouter();
	const { id, sid } = query;

	if (Number.isNaN(Number.parseFloat(sid))) {
		return <RedirectLegacyPlanPageWithoutScenarioId />;
	}

	return (
		<ScreenRedirect
			to={`/jira/plans/${id}/scenarios/${sid}`}
			persistSearchParams={PERSIST_SEARCH_PARAMS}
		/>
	);
};

export const RedirectPlanPageWithoutScenarioId = () => {
	const [{ match }] = useRouter();
	const { planId } = match.params;

	if (planId === null || planId === undefined || Number.isNaN(Number.parseFloat(planId))) {
		throw new Error('Plan cannot be loaded without planId');
	}

	const { data, loading } = useResource(advancedRoadmapsPlanPageInitialChecksResource);

	if (loading || data === null) {
		return <Spinner />;
	}

	if (!data.success) {
		return <ErrorScreen errorMessage={data.errorMessage} />;
	}

	if (
		data.scenarioId === null ||
		data.scenarioId === undefined ||
		(typeof data.scenarioId === 'string' && Number.isNaN(Number.parseFloat(data.scenarioId)))
	) {
		return null;
	}

	return (
		<ScreenRedirect
			to={`/jira/plans/${planId}/scenarios/${data.scenarioId}`}
			persistSearchParams={PERSIST_SEARCH_PARAMS}
		/>
	);
};
