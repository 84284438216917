import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ROUTE_GROUPS_SERVICEDESK } from '@atlassian/jira-common-constants/src/spa-routes';
import { AsyncDirectoryNav } from '@atlassian/jira-horizontal-nav-jsm-directory';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async';
import { createEntry } from '@atlassian/jira-route-entry';
import type { Route } from '@atlassian/jira-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common';
import { entitlementsCustomerQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-customer-details/src/services/entitlements-customer-query-resource/index.tsx';
import { uiCustomerDetailsPageQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-customer-details/src/services/ui-customer-details-page-query-resource/index.tsx';
import {
	servicedeskCustomersV2Route,
	servicedeskCustomerDetailsRoute,
} from '@atlassian/jira-router-routes-servicedesk-customers-routes';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout';
import { APP_NAMES, toPackageName } from '@atlassian/jira-servicedesk-common/src/utils/app-names';
import {
	ServiceDeskCustomersV2,
	LazyServicedeskCustomersV2,
	ServicedeskCustomerDetails,
	LazyServicedeskCustomerDetails,
} from './ui';

export { ServiceDeskCustomersV2 };

const getCustomersRoutesCommon = () => ({
	group: ROUTE_GROUPS_SERVICEDESK,
	exact: true,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},
	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
	],
});

export const customersRouteEntries: Route[] = [
	createEntry(servicedeskCustomersV2Route, {
		...getCustomersRoutesCommon(),
		component: ServiceDeskCustomersV2,
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomersV2],
		perfMetricKey: 'jsm-customers-component-load',
		ufoName: 'jsm-customers-component-load',

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncDirectoryNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorziontalOnNav4: true,
		},
	}),
	createEntry(servicedeskCustomerDetailsRoute, {
		...getCustomersRoutesCommon(),
		component: ServicedeskCustomerDetails,
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomerDetails],
		perfMetricKey: 'jsm-customer-details-component-load',

		meta: {
			reporting: {
				id: APP_NAMES.CUSTOMER_DETAILS,
				packageName: toPackageName(APP_NAMES.CUSTOMER_DETAILS),
				teamName: 'boysenberry',
			},
		},

		resources: [
			...getCustomersRoutesCommon().resources,
			entitlementsCustomerQueryResource,
			uiCustomerDetailsPageQueryResource,
		],
	}),
];
