import React, { memo, useEffect, useMemo, useState } from 'react';
import { NestableNavigationContent, Section } from '@atlaskit/side-navigation';
import {
	ROUTE_GROUPS_PROJECT_SETTINGS_CORE,
	ROUTE_NAMES_PROJECT_SETTINGS_CORE_FORGE_APPS,
} from '@atlassian/jira-common-constants/src/spa-routes';
import { CONNECT_ROUTES_SET } from '@atlassian/jira-common-constants/src/spa-routes-sets';
import useAutoExpandSidebar from '@atlassian/jira-navigation-apps-sidebar-core/src/controllers/auto-expand-sidebar/index.tsx';
import { Settings } from '@atlassian/jira-navigation-apps-sidebar-core/src/ui/multi-project-menu/active-project/project-menu/settings-menu/settings/main.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import type { Route } from '@atlassian/react-resource-router';
import { SECTION_ID, FORGE_ROUTES_SET } from '../../../../../common/constants';
import type { Project } from '../../../../../common/types';
import {
	useProjectSettingsConnectItems,
	useProjectSettingsForgeItems,
} from '../../../../../controllers';
import { SettingsMenu as SettingsMenuNextGen } from './settings-nextgen';

const EMPTY_STACK: string[] = [];

const adjustAppStack = (appStack: Array<string>) => {
	const [first, ...other] = appStack;

	// omit the settings nesting level as we render the settings menu
	// as a top level menu in business projects
	if (SECTION_ID.SETTINGS === first) {
		return other;
	}

	return appStack;
};

const calculateStack = (
	route: Route,
	connectStack: null | Array<string>,
	forgeStack: null | Array<string>,
) => {
	// should be handled above generic PROJECT_SETTINGS_CORE logic
	if (FORGE_ROUTES_SET.has(route.name) && forgeStack) {
		return adjustAppStack(forgeStack);
	}

	if (route.name.includes(ROUTE_GROUPS_PROJECT_SETTINGS_CORE)) {
		// this is the same logic as the one in src/packages/navigation-apps/sidebar/next-gen/src/ui/menu/main.js
		const [view] = route.name.replace(`${ROUTE_GROUPS_PROJECT_SETTINGS_CORE}-`, '').split('-');
		return view === 'issuetypes' ? [view] : EMPTY_STACK;
	}

	if (CONNECT_ROUTES_SET.has(route.name) && connectStack) {
		return adjustAppStack(connectStack);
	}

	return EMPTY_STACK;
};

type Props = {
	project: Project;
};

const SettingsMenu = ({ project }: Props) => {
	useAutoExpandSidebar();
	const route = useCurrentRoute();
	const { data: connectItems, stack: connectStack } = useProjectSettingsConnectItems();
	const { data: forgeItems, stack: forgeStack } = useProjectSettingsForgeItems(
		ROUTE_NAMES_PROJECT_SETTINGS_CORE_FORGE_APPS,
		EMPTY_STACK,
	);
	const ecosystemItems = useMemo(
		() => ({
			forge: forgeItems,
			connect: connectItems,
		}),
		[forgeItems, connectItems],
	);

	const [stack, setStack] = useState<string[]>(() =>
		calculateStack(route, connectStack, forgeStack),
	);

	useEffect(() => {
		setStack(calculateStack(route, connectStack, forgeStack));
	}, [route, connectStack, forgeStack]);

	return (
		<NestableNavigationContent stack={stack} onChange={setStack}>
			<Section>
				{project.isSimplified ? (
					<SettingsMenuNextGen stack={stack} />
				) : (
					<>
						<Settings ecosystemItems={ecosystemItems} />
					</>
				)}
			</Section>
		</NestableNavigationContent>
	);
};

export default memo<Props>(SettingsMenu);
