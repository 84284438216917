import log from '@atlassian/jira-common-util-logging/src/log';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import { createResource, useResource } from '@atlassian/react-resource-router';
import {
	PACKAGE_NAME,
	SMART_REPLIES_EXPERIENCE_ID,
	RESOURCE_TYPE_SMART_REPLIES_STATUS,
} from './constants';

export type SmartRepliesPreference = {
	isEnabled: boolean;
};
export type SmartRepliesOptInStatusResourceData = {
	smartRepliesOptInStatus: SmartRepliesPreference | null;
};

export const smartRepliesOptInStatusResources = createResource<SmartRepliesOptInStatusResourceData>(
	{
		type: RESOURCE_TYPE_SMART_REPLIES_STATUS,
		getKey: () => SMART_REPLIES_EXPERIENCE_ID,
		getData: async (): Promise<SmartRepliesOptInStatusResourceData> => {
			let smartRepliesOptInStatus = null;

			try {
				smartRepliesOptInStatus = await userPreferenceAPI.get<SmartRepliesPreference>(
					SMART_REPLIES_EXPERIENCE_ID,
				);
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (error.statusCode !== 404) {
					log.safeErrorWithoutCustomerData(SMART_REPLIES_EXPERIENCE_ID, PACKAGE_NAME, error);
				}
				fireErrorAnalytics({
					meta: {
						id: 'smartRepliesOptInStatusResources',
						packageName: PACKAGE_NAME,
						teamName: 'jira-warepil',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
			return {
				smartRepliesOptInStatus,
			};
		},
		maxAge: Number.MAX_VALUE,
	},
);

export const useSmartRepliesPreferencesResources = () => {
	const { data, refresh, loading } = useResource(smartRepliesOptInStatusResources);

	return {
		isSmartRepliesPreferenceEnabled: data?.smartRepliesOptInStatus?.isEnabled ?? true,
		loading,
		refreshData: refresh,
	};
};
