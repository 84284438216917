import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { CycleTimeReportSpa as CycleTimeReportType } from '@atlassian/jira-spa-apps-software-reports-cycle-time-report/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyCycleTimeReport = lazyForPaint<typeof CycleTimeReportType>(
	() =>
		import(
			/* webpackChunkName: "async-cycle-time-report", jiraSpaEntry: "async-cycle-time-report" */ '@atlassian/jira-spa-apps-software-reports-cycle-time-report'
		),
);

export const CycleTimeReportNextGen = () => (
	<LazyPage Page={LazyCycleTimeReport} pageId="cycleTimeReportNextGen" shouldShowSpinner />
);

export const CycleTimeReportClassic = () => (
	<LazyPage Page={LazyCycleTimeReport} pageId="cycleTimeReportClassic" shouldShowSpinner />
);
