import React, { memo, useMemo } from 'react';
import { ROUTE_NAMES_FORGE_PROJECT } from '@atlassian/jira-common-constants/src/spa-routes';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectPage } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { isExtensionVisible } from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import {
	forgeProjectModuleV1Resource,
	forgeProjectModuleV2Resource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import useAutoExpandSidebar from '@atlassian/jira-navigation-apps-sidebar-core/src/controllers/auto-expand-sidebar/index.tsx';
import { useForgeSelectedApp } from '@atlassian/jira-navigation-apps-sidebar-forge/src/common/utils/selected-app/index.tsx';
import { useProjectForgeApps } from '@atlassian/jira-navigation-apps-sidebar-forge/src/controllers/project/index.tsx';
import { ForgeProjectSection } from '@atlassian/jira-navigation-apps-sidebar-forge/src/ui/project-section/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useResource } from '@atlassian/react-resource-router';

const ForgeMenuV1 = () => {
	useAutoExpandSidebar();
	const route = useCurrentRoute();
	const { data: forgeResourceData } = useResource(forgeProjectModuleV1Resource);
	const projectData = useMemo(() => forgeResourceData?.extensions || null, [forgeResourceData]);
	const selectedForgeApp = useForgeSelectedApp(projectData, route.name);
	const { stack: forgeStack } = useProjectForgeApps(ROUTE_NAMES_FORGE_PROJECT);

	if (selectedForgeApp != null && forgeStack != null && forgeStack.length > 0) {
		return (
			<ForgeProjectSection
				overrides={{
					showSelectedAppMenuOnly: true,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					selectedApp: selectedForgeApp as ProjectPage,
				}}
			/>
		);
	}

	return null;
};

const ForgeMenuV2 = () => {
	useAutoExpandSidebar();
	const route = useCurrentRoute();
	const { data: forgeResourceData } = useResource(forgeProjectModuleV2Resource);
	const projectData = useMemo(
		() => forgeResourceData?.filter(isExtensionVisible) || null,
		[forgeResourceData],
	);
	const selectedForgeApp = useForgeSelectedApp(projectData, route.name);
	const { stack: forgeStack } = useProjectForgeApps(ROUTE_NAMES_FORGE_PROJECT);

	if (selectedForgeApp != null && forgeStack != null && forgeStack.length > 0) {
		return (
			<ForgeProjectSection
				overrides={{
					showSelectedAppMenuOnly: true,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					selectedApp: selectedForgeApp as ProjectPage,
				}}
			/>
		);
	}

	return null;
};

const ForgeMenu = componentWithCondition(
	() => fg('new_graphql_endpoint_for_fetching_forge_modules'),
	ForgeMenuV2,
	ForgeMenuV1,
);

export default memo(ForgeMenu);
