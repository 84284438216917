import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { OrganizationDetailsAppLayout as ServicedeskOrganizationDetailsType } from '@atlassian/jira-spa-apps-servicedesk-organization-details/src/ui/index.tsx';
import type { OrganizationsAppLayout as ServicedeskOrganizationsType } from '@atlassian/jira-spa-apps-servicedesk-organizations/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyServicedeskOrganizations = lazyForPaint<typeof ServicedeskOrganizationsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-organizations", jiraSpaEntry: "async-servicedesk-organizations" */ '@atlassian/jira-spa-apps-servicedesk-organizations'
		),
	),
);

export const ServiceDeskOrganizations = () => (
	<LazyPage
		Page={LazyServicedeskOrganizations}
		pageId="spa-apps/servicedesk/organizations"
		shouldShowSpinner
	/>
);

export const LazyServicedeskOrganizationDetails = lazyForPaint<
	typeof ServicedeskOrganizationDetailsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-organization-details", jiraSpaEntry: "async-servicedesk-organization-details" */ '@atlassian/jira-spa-apps-servicedesk-organization-details'
		),
	),
);

export const ServicedeskOrganizationDetails = () => (
	<LazyPage
		Page={LazyServicedeskOrganizationDetails}
		pageId="spa-apps/servicedesk/organization-details"
		shouldShowSpinner
	/>
);
