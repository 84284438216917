import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async';
import { ff } from '@atlassian/jira-feature-flagging';
import { globalLayout } from '@atlassian/jira-global-layout';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarPlan from '@atlassian/jira-navigation-apps-sidebar-plan/src/async';
import { planLayout } from '@atlassian/jira-plan-layout';
import { createEntry } from '@atlassian/jira-route-entry';
import { composeLayouts } from '@atlassian/jira-route-layout';
import type { Route } from '@atlassian/jira-router';
import {
	advancedRoadmapsScenarioIdResource,
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
	advancedRoadmapsPlanReportPageInitialChecksResource,
	advancedRoadmapsLastVisitedProgramBoardIdResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { advancedRoadmapsSummaryDataResource } from '@atlassian/jira-router-resources-advanced-roadmaps-summary/src/services/summary-data/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import {
	softwareAdvancedRoadmapsRoute,
	softwarePlanDependenciesRoute,
	softwarePlanSummaryRoute,
	softwarePlanCalendarRoute,
	softwarePlanReleasesRoute,
	softwarePlanTimelineRoute,
	softwarePlanSettingsPermissionsRoute,
	softwarePlanSettingsSavedViewsRoute,
	softwarePlanSettingsProgramBoardsRoute,
	softwarePlanSettingsPlanInfoRoute,
	softwarePlanSettingsSchedulingRoute,
	softwarePlanSettingsScenariosRoute,
	softwarePlanSettingsAutoSchedulerRoute,
	softwarePlanSettingsCustomFieldsRoute,
	softwarePlanSettingsExclusionRulesRoute,
	softwarePlanSettingsIssueSourcesRoute,
	softwarePlanSettingsFindYourIssueRoute,
	softwarePlanSettingsRemovedIssuesRoute,
	softwarePlanTeamsRoute,
	softwareAdvancedRoadmaps1Route,
	softwareAdvancedRoadmapsRedirectRoute,
	softwareAdvancedRoadmaps3Route,
	softwareAdvancedRoadmapsRedirect1Route,
	softwareAdvancedRoadmaps5Route,
	softwareAdvancedRoadmapsSetupRoute,
	softwareAdvancedRoadmapsGettingStartedRedirectRoute,
} from '@atlassian/jira-router-routes-advanced-roadmaps-routes';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/index.tsx';
import { CalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/calendar/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';
import {
	RedirectLegacyPlanPage,
	RedirectPlanPageWithoutScenarioId,
} from './common/ui/redirect/plan';
import RedirectLegacyReportPage from './common/ui/redirect/report';
import { arjSettingsRouteEntries } from './ui/settings';
import {
	archivedPlansRouteEntry,
	trashPlansRouteEntry,
	trashedPlanDirectLinkRouteEntry,
	arjSettingsFinancialYearPageEntry,
	planIncrementRouteEntry,
	planIncrementsRouteEntry,
} from './ui/spa';
import PlanCalendarPage, { LazyPlanCalendar, planCalendarRouteEntry } from './ui/spa/plan-calendar';
import PlanDependenciesReportPage, {
	LazyPlanDependenciesReport,
} from './ui/spa/plan-dependencies-report';
import PlanReleasesPage, { LazyPlanReleases } from './ui/spa/plan-releases';
import PlanReportPage from './ui/spa/plan-report';
import PlanPage, { LazyAdvancedRoadmapsPlan } from './ui/spa/plan-report/plan';
import PlanSettingsAutoSchedulerPage, {
	LazyPlanSettingsAutoScheduler,
} from './ui/spa/plan-settings-auto-scheduler';
import PlanSettingsCustomFieldsPage, {
	LazyPlanSettingsCustomFields,
} from './ui/spa/plan-settings-custom-fields';
import PlanSettingsExclusionRulesPage, {
	LazyPlanSettingsExclusionRules,
} from './ui/spa/plan-settings-exclusion-rules';
import PlanSettingsIssueFindYourIssuePage, {
	LazyPlanSettingsFindYourIssue,
} from './ui/spa/plan-settings-find-your-issue';
import PlanSettingsIssueSourcesPage, {
	LazyPlanSettingsIssueSources,
} from './ui/spa/plan-settings-issue-sources';
import PlanSettingsPermissionsPage, {
	LazyPlanSettingsPermissions,
} from './ui/spa/plan-settings-permissions';
import PlanSettingsPlanInfoPage, {
	LazyPlanSettingsPlanInfo,
} from './ui/spa/plan-settings-plan-info';
import PlanSettingsProgramBoardsPage, {
	LazyPlanSettingsProgramBoards,
} from './ui/spa/plan-settings-program-boards';
import PlanSettingsRemovedIssuesPage, {
	LazyPlanSettingsRemovedIssues,
} from './ui/spa/plan-settings-removed-issues';
import PlanSettingsSavedViewsPage, {
	LazyPlanSettingsSavedViews,
} from './ui/spa/plan-settings-saved-views';
import PlanSettingsScenariosPage, {
	LazyPlanSettingsScenarios,
} from './ui/spa/plan-settings-scenarios';
import PlanSettingsSchedulingPage, {
	LazyPlanSettingsScheduling,
} from './ui/spa/plan-settings-scheduling';
import PlanSummaryPage, { LazyPlanSummary } from './ui/spa/plan-summary';
import PlanTeamsPage, { LazyPlanTeams } from './ui/spa/plan-teams';
import PlanTimelinePage, { LazyPlanTimeline } from './ui/spa/plan-timeline';
import SetupPage, { LazyAdvancedRoadmapsSetup } from './ui/spa/setup';
import withRedirection from './ui/spa/sidebar-navigation-redirection';

export { arjSettingsRouteEntries } from './ui/settings';

export const advancedRoadmapsRouteEntries = [
	archivedPlansRouteEntry,
	trashPlansRouteEntry,
	trashedPlanDirectLinkRouteEntry,
	arjSettingsFinancialYearPageEntry,
	planIncrementRouteEntry,
	planIncrementsRouteEntry,
];

// Move advancedRoadmapsLastVisitedProgramBoardIdResource back into the resources array on clean up
const lastVisitedProgramBoardResource = resourceWithCondition2(
	() => ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1'),
	advancedRoadmapsLastVisitedProgramBoardIdResource,
);

export const planPageRouteEntry: Route = createEntry(softwareAdvancedRoadmapsRoute, {
	ufoName: 'software-advanced-roadmaps-scenario',

	component: withRedirection(PlanPage, {
		to: '/timeline',
		redirectCondition: (isSidebarVisible: boolean) => isSidebarVisible,
	}),

	layout: globalLayout,

	resources: [
		...getNavigationResources(),
		...getNavigationSidebarPlanResources(),
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		lastVisitedProgramBoardResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	navigation: {
		menuId: MENU_ID.PLANS,
	},

	forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan],
});

export const planRouteEntries: Route[] = [
	...arjSettingsRouteEntries,
	createEntry(softwarePlanDependenciesRoute, {
		component: withRedirection(PlanDependenciesReportPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),
		layout: composeLayouts(globalLayout, planLayout),
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			lastVisitedProgramBoardResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanDependenciesReport],
	}),
	createEntry(softwarePlanSummaryRoute, {
		ufoName: 'software-advanced-roadmaps-summary',

		component: withRedirection(PlanSummaryPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsSummaryDataResource,
			lastVisitedProgramBoardResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSummary],
	}),
	createEntry(softwarePlanCalendarRoute, {
		ufoName: 'software-advanced-roadmaps-calendar',

		component: withRedirection(PlanCalendarPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}), // this is only used when entryPoint() returns undefined

		skeleton: () => (ff('platform-calendar-adoption-in-plans') ? <CalendarSkeleton /> : undefined),

		entryPoint() {
			return ff('platform-calendar-adoption-in-plans') ? planCalendarRouteEntry : undefined;
		},

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			lastVisitedProgramBoardResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanCalendar],
	}),
	createEntry(softwarePlanReleasesRoute, {
		component: withRedirection(PlanReleasesPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			lastVisitedProgramBoardResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanReleases],
	}),
	createEntry(softwarePlanTimelineRoute, {
		component: withRedirection(PlanTimelinePage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
			lastVisitedProgramBoardResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanTimeline],
	}),
	createEntry(softwarePlanSettingsPermissionsRoute, {
		component: withRedirection(PlanSettingsPermissionsPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsPermissions],
	}),
	createEntry(softwarePlanSettingsSavedViewsRoute, {
		component: withRedirection(PlanSettingsSavedViewsPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsSavedViews],
	}),
	createEntry(softwarePlanSettingsPlanInfoRoute, {
		component: withRedirection(PlanSettingsPlanInfoPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsPlanInfo],
	}),
	createEntry(softwarePlanSettingsProgramBoardsRoute, {
		component: withRedirection(PlanSettingsProgramBoardsPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) =>
				!isSidebarVisible || !ff('com.atlassian.rm.jpo.jpo3cloud.increment-planning-board-m1'),
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsProgramBoards],
	}),
	createEntry(softwarePlanSettingsSchedulingRoute, {
		component: withRedirection(PlanSettingsSchedulingPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsScheduling],
	}),
	createEntry(softwarePlanSettingsScenariosRoute, {
		component: withRedirection(PlanSettingsScenariosPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsScenarios],
	}),
	createEntry(softwarePlanSettingsAutoSchedulerRoute, {
		component: withRedirection(PlanSettingsAutoSchedulerPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsAutoScheduler],
	}),
	createEntry(softwarePlanSettingsCustomFieldsRoute, {
		component: withRedirection(PlanSettingsCustomFieldsPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsCustomFields],
	}),
	createEntry(softwarePlanSettingsExclusionRulesRoute, {
		component: withRedirection(PlanSettingsExclusionRulesPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsExclusionRules],
	}),
	createEntry(softwarePlanSettingsIssueSourcesRoute, {
		component: withRedirection(PlanSettingsIssueSourcesPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsIssueSources],
	}),
	createEntry(softwarePlanSettingsFindYourIssueRoute, {
		component: withRedirection(PlanSettingsIssueFindYourIssuePage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsFindYourIssue],
	}),
	createEntry(softwarePlanSettingsRemovedIssuesRoute, {
		component: withRedirection(PlanSettingsRemovedIssuesPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			sidebarId: SIDEBAR_ID.PLAN_SETTINGS,
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanSettingsRemovedIssues],
	}),
	createEntry(softwarePlanTeamsRoute, {
		component: withRedirection(PlanTeamsPage, {
			to: '',
			redirectCondition: (isSidebarVisible: boolean) => !isSidebarVisible,
		}),

		layout: composeLayouts(globalLayout, planLayout),

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
			advancedRoadmapsReduxStoreStateResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			menuId: MENU_ID.PLANS,
			sidebar: AsyncSidebarPlan,
			horizontal: AsyncHorizontalNavPlans,
			onlyShowHorziontalOnNav4: true,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanTeams],
	}),
	createEntry(softwareAdvancedRoadmapsGettingStartedRedirectRoute, {
		component: () => <ScreenRedirect to="/jira/plans?create-sample-plan=true" />,
		isRedirect: true,
		layout: globalLayout,
		resources: [...getNavigationResources()],

		navigation: {
			menuId: MENU_ID.PLANS,
		},

		forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan],
	}),
];

export const planPageWithoutScenarioIdRouteEntry: Route = createEntry(
	softwareAdvancedRoadmaps1Route,
	{
		ufoName: 'software-advanced-roadmaps-plan',
		component: RedirectPlanPageWithoutScenarioId,
		layout: globalLayout,

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			menuId: MENU_ID.PLANS,
		},

		forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan],
	},
);

export const legacyPlanPageRedirectRouteEntry: Route = createEntry(
	softwareAdvancedRoadmapsRedirectRoute,
	{
		component: RedirectLegacyPlanPage,
		isRedirect: true,
		layout: globalLayout,
		resources: [...getNavigationResources(), advancedRoadmapsScenarioIdResource],
		navigation: {
			menuId: MENU_ID.PLANS,
		},
		forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan],
	},
);

/**
 * Report Page
 */

export const planReportPageRouteEntry: Route = createEntry(softwareAdvancedRoadmaps3Route, {
	ufoName: 'software-advanced-roadmaps-report',
	component: PlanReportPage,
	layout: composeLayouts(globalLayout, planLayout),
	resources: [
		...getNavigationResources(),
		advancedRoadmapsPlanReportPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		lastVisitedProgramBoardResource,
	],
	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},
	navigation: {
		menuId: MENU_ID.PLANS,
	},
	forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan],
});

export const legacyPlanReportPageRedirectRouteEntry: Route = createEntry(
	softwareAdvancedRoadmapsRedirect1Route,
	{
		component: RedirectLegacyReportPage,
		isRedirect: true,
		layout: globalLayout,
		resources: [...getNavigationResources()],

		navigation: {
			menuId: MENU_ID.PLANS,
		},

		forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsPlan],
	},
);

/**
 * Export Page
 */

const ExportPageComponent = () => <LazyAdvancedRoadmapsPlan viewType="export" />;

const ExportPage = () => (
	<LazyPage Page={ExportPageComponent} pageId="advanced-roadmap" shouldShowSpinner />
);

export const planExportPageRouteEntry: Route = createEntry(softwareAdvancedRoadmaps5Route, {
	ufoName: 'software-advanced-roadmaps-scenario-export',
	component: ExportPage,
	layout: { isChromeless: true, globalComponents: [], rightSidebars: [] },
	resources: [advancedRoadmapsPlanPageInitialChecksResource],
	forPaint: [LazyAdvancedRoadmapsPlan],
});

export const planSetupPageRouteEntry: Route = createEntry(softwareAdvancedRoadmapsSetupRoute, {
	component: SetupPage,
	layout: globalLayout,
	resources: [...getNavigationResources(), advancedRoadmapsPlanPageInitialChecksResource],
	forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsSetup],
});
