import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import type SoftwareNotificationsIssueLogsType from '@atlassian/jira-spa-apps-project-settings-software-notifications-issue-logs';
import { notificationsIssueLogsEntryPoint } from '@atlassian/jira-spa-apps-project-settings-software-notifications-issue-logs/entrypoint';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const emailNotificationsLogEntryPointPage = createPageEntryPoint({
	main: notificationsIssueLogsEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const LazySoftwareNotificationsIssueLogs = lazyForPaint<
	typeof SoftwareNotificationsIssueLogsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-software-notifications-issue-logs", jiraSpaEntry: "async-project-settings-software-notifications-issue-logs" */ '@atlassian/jira-spa-apps-project-settings-software-notifications-issue-logs'
		),
	),
);

export const SoftwareNotificationsIssueLogs = () => (
	<LazyPage
		Page={LazySoftwareNotificationsIssueLogs}
		pageId="project-settings-software-notifications-issue-logs"
		shouldShowSpinner={false}
	/>
);
