import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type ControlReportSpa from '@atlassian/jira-spa-apps-software-reports-control-report/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

export const LazyNextGenControlReport = lazyForPaint<typeof ControlReportSpa>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-control-report", jiraSpaEntry: "async-control-report" */ '@atlassian/jira-spa-apps-software-reports-control-report'
		),
	),
);

export const ControlPage = () => (
	<LazyPage Page={LazyNextGenControlReport} pageId="controlReportNextGen" shouldShowSpinner />
);
