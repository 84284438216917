// eslint-disable-next-line jira/restricted/react-component-props
import React, { forwardRef, type ComponentProps } from 'react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import BreadcrumbsComponent, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { useIntl } from '@atlassian/jira-intl';
import { Link } from '@atlassian/jira-router';
import type { Breadcrumb } from '../model/types';
import BreadCrumbItemWithExtraAnalytics from './breadcrumb-item';
import messages from './messages';

type Props = {
	isVisible: boolean;
	items: Breadcrumb[];
};

export const AnchorLink = forwardRef<HTMLAnchorElement, ComponentProps<typeof Link>>(
	(props, ref) => <Link {...props} ref={ref} type="a" />,
);

export default function Breadcrumbs(props: Props) {
	const { isVisible, items } = props;
	const { formatMessage } = useIntl();
	return isVisible ? (
		// onExpand is required prop in atlaskit's breadcrumbitem component
		<>
			<BreadcrumbsComponent onExpand={noop}>
				{isEmpty(items) ? (
					// This is needed in order to render an empty breadcrumb for SSR, to avoid visual jumping when javascript kicks in.
					<BreadcrumbsItem text="" aria-label={formatMessage(messages.breadcrumbsLabel)} />
				) : (
					items.map((breadcrumb, index) => {
						const breadcrumbItem = (
							<BreadCrumbItemWithExtraAnalytics
								key={index}
								index={index}
								hasSeparator
								text={breadcrumb.text}
								target={__SPA__ ? undefined : breadcrumb.target}
								href={breadcrumb.href}
								component={__SPA__ ? AnchorLink : undefined}
								legacyId={breadcrumb.legacyId}
								type={breadcrumb.type}
								analyticsAttributes={breadcrumb.analyticsAttributes}
								analyticsFields={breadcrumb.analyticsFields}
							/>
						);

						return breadcrumbItem;
					})
				)}
			</BreadcrumbsComponent>
		</>
	) : null;
}
