import React from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import type PlanCalendarType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-calendar';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-calendar/entrypoint';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanCalendar = lazyForPaint<typeof PlanCalendarType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-calendar", jiraSpaEntry: "async-plan-calendar" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-calendar'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <LazyPage Page={LazyPlanCalendar} pageId="plan-calendar" shouldShowSpinner />;

export const planCalendarRouteEntry = createPageEntryPoint({
	main: calendarEntryPoint,
	topNavigationMenuId: MENU_ID.PLANS,
});
